import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { blue } from '@ant-design/colors';
import { selectBtcRates } from 'store/btc-rates';
import { selectRates } from 'store/rates';
import { sort, selectPayments, getAllPayments } from 'store/payments';
import { DEFAULT_PAGINATION, useTableParams } from 'hooks/useTableParams';
import SortableTable from 'components/Table/SortableTable';
import { AmountByRate } from 'components/Table/AmountCell';
import { getTotal } from './utils';
import Footer from './Footer';
import StatusSwitch from './StatusSwitch';

const PaymentsTable = () => {
  const dispatch = useDispatch();
  const { latest: latestBtcRate } = useSelector(selectBtcRates);
  const { latest: latestEurRate } = useSelector(selectRates);
  const [editMode, setEditMode] = useState(false);

  const { params, setParams, resetParams } = useTableParams({
    isDeleted: false,
  });
  const { list, loading } = useSelector(selectPayments);

  const total = useMemo(() => getTotal(list.docs, latestBtcRate, latestEurRate), [list, latestBtcRate, latestEurRate]);

  const fetchPayments = useCallback(() => {
    dispatch(getAllPayments(params));
  }, [dispatch, params]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: (
          <>
            Balance
            <Divider style={{ margin: '10px 0' }} />
            <p style={{ textAlign: 'center', marginBottom: 0 }}>{total}$</p>
          </>
        ),
        dataIndex: 'balance',
        key: 'balance',
        align: 'center',
        render: (amount, record) => (
          <AmountByRate
            amount={amount}
            currency={record.currency}
            latestEurRate={latestEurRate}
            latestBtcRate={latestBtcRate}
          />
        ),
      },
    ];

    if (editMode) {
      defaultColumns.push({
        title: 'Actions',
        key: 'actions',
        dataIndex: '_id',
        align: 'center',
        render: (id, record) => <StatusSwitch id={id} blocked={record.isDeleted} onToggleStatus={fetchPayments} />,
      });
    }

    return defaultColumns;
  }, [latestBtcRate, latestEurRate, editMode, fetchPayments, total]);

  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode);
    if (editMode) {
      resetParams();
    }
  }, [editMode, resetParams]);

  const toggleShowHidden = useCallback(
    () =>
      setParams((current) => ({
        ...current,
        pagination: DEFAULT_PAGINATION,
        isDeleted: !current.isDeleted,
      })),
    [setParams],
  );

  const handleDragSort = useCallback((data) => dispatch(sort(data)), [dispatch]);

  const footer = useCallback(
    () => (
      <Footer
        editMode={editMode}
        onToggleEditMode={toggleEditMode}
        showHidden={params.isDeleted}
        onToggleShowHidden={toggleShowHidden}
      />
    ),
    [editMode, params.isDeleted, toggleShowHidden, toggleEditMode],
  );

  const style = useMemo(() => (editMode ? { border: `2px dashed ${blue.primary}` } : {}), [editMode]);

  return (
    <SortableTable
      active={editMode}
      footer={footer}
      dataSource={list.docs}
      columns={columns}
      loading={loading}
      onDragSort={handleDragSort}
      style={style}
      className="payments-table"
      scroll={{ y: 'calc(100vh - 335px)' }}
    />
  );
};

export default PaymentsTable;
