import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { Col, Form, Input, Row, Select } from 'antd';
import { CountryRegionData } from 'react-country-region-selector';
import debounce from 'lodash.debounce';
import { generatePassword, getMaxPercent } from 'utils/users';
import { toBuyInPercent, toUpperCase } from 'utils/format';
import { rangeWithStep } from 'utils/generators';
import { validateUnique } from 'store/users';
import { useDegree } from '../../hooks';
import InputNumber from 'components/Form/InputNumber';

const PersonalInfo = ({ form, onChange }) => {
  const dispatch = useDispatch();
  const [maxBuyInPercent, setMaxBuyInPercent] = useState(0);
  const [isCourseDisabled, setIsCourseDisabled] = useState(true);

  const onDegreeChange = useCallback(
    (valid, value) => {
      const resetFields = ['buyInPercent'];
      if (!valid) {
        resetFields.push('scheduleCourse');
        resetFields.push('course');
        setIsCourseDisabled(true);
      }
      setMaxBuyInPercent(getMaxPercent(value));
      form.resetFields(resetFields);
    },
    [form],
  );

  const { degree, onCourseChange } = useDegree(onDegreeChange);

  const checkUniqueness = useCallback(
    async ({ field }, value) => {
      try {
        await dispatch(validateUnique({ [field]: value }));
      } catch (e) {
        const code = e.response?.status;
        if (code === 409) {
          throw new Error('Already exists');
        }
        throw new Error('Something went wrong');
      }
    },
    [dispatch],
  );

  const debouncedCheckUniqueness = debounce(checkUniqueness, 500);

  const validateUniqueness = useCallback(
    () => ({
      validator: debouncedCheckUniqueness,
    }),
    [debouncedCheckUniqueness],
  );

  const buyInPercents = useMemo(
    () => rangeWithStep(0, maxBuyInPercent, 5).map((p) => ({ v: p, key: toBuyInPercent(p) })),
    [maxBuyInPercent],
  );

  useEffect(() => {
    const scheduleCourse = form.getFieldValue('scheduleCourse');
    if (scheduleCourse !== undefined && scheduleCourse !== null) {
      setIsCourseDisabled(false);
      form.setFieldsValue({ course: Math.floor(scheduleCourse) });
    } else {
      setIsCourseDisabled(true);
      form.setFieldsValue({ course: null });
    }
  }, [form.getFieldValue('scheduleCourse')]);

  const handleValuesChange = (changedValues) => {
    if ('scheduleCourse' in changedValues) {
      const updatedCourse = Math.floor(changedValues.scheduleCourse);
      form.setFields([{ name: 'course', value: updatedCourse, touched: true }]); // Mark `course` as changed
    }
  };

  const handleFieldChange = useCallback(
    (_, fields) => onChange(!fields.some((field) => !!field.errors.length)),
    [onChange],
  );

  const validateCourse = (form) => (_, value) => {
    const scheduleCourse = form.getFieldValue('scheduleCourse');
    if (!Number.isInteger(value)) {
      return Promise.reject(new Error('Course must be an integer'));
    }
    if (Math.abs(value - scheduleCourse) >= 1) {
      return Promise.reject(new Error('Difference between Course and Schedule Course must be < 1'));
    }
    return Promise.resolve();
  };

  return (
    <Form
      name="create-user"
      form={form}
      layout="vertical"
      initialValues={{
        ABI: 10,
        password: generatePassword(),
        buyInPercent: null,
      }}
      onFieldsChange={handleFieldChange}
      onValuesChange={handleValuesChange}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Login"
            name="username"
            rules={[{ required: true, message: 'Required' }, validateUniqueness]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please, use the valid email' }, validateUniqueness]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Full Name (Latin)"
            name="name"
            rules={[
              { required: true, message: 'Required' },
              { pattern: new RegExp('^[a-zA-Z\\s\\-]*$'), message: 'Use latin letters only' },
            ]}
            normalize={toUpperCase}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Discord ID" name="discordId">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} style={{ marginBottom: -14 }}>
        <Col span={12}>
          <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Required' }]}>
            <Select name="country" showSearch>
              {CountryRegionData.map((country) => (
                <Select.Option key={country[1]} value={country[0]}>
                  {country[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Schedule Course"
            name="scheduleCourse"
            rules={[{ required: true, message: 'Required' }]}
          >
            <InputNumber
              precision={2}
              onChange={onCourseChange}
              addonAfter={degree}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Course"
            name="course"
            rules={[{ required: true, message: 'Required' }, { validator: validateCourse(form) }]}
          >
            <InputNumber precision={0}  disabled={isCourseDisabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item label="Conditions" name="buyInPercent" rules={[{ required: true, message: 'Required' }]}>
            <Select name="buyInPercent" style={{ width: '100%' }} virtual={false}>
              {buyInPercents.map(({ key, v }) => (
                <Select.Option value={v} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item hidden name="password" rules={[{ required: true }]} />
    </Form>
  );
};

export default PersonalInfo;
