import { createContext, forwardRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { updateTransferConfig } from 'store/transfer-config';
import useLoading from 'hooks/useLoading';

export const Context = createContext();

const GridRow = forwardRef(({ record, ...props }, ref) => {
  const [statuses, setStatuses] = useState(record?.statuses || []);
  const dispatch = useDispatch();
  const { execute, loading } = useLoading();

  const onChange = useCallback(
    (e) => {
      const { value, checked } = e.target;
      let newStatuses;
      const idx = ACCOUNT_REGIONS[record.type].statuses.indexOf(value);

      if (checked) {
        newStatuses = ACCOUNT_REGIONS[record.type].statuses.slice(idx);
      } else {
        newStatuses = ACCOUNT_REGIONS[record.type].statuses.filter((_, i) => i > idx);
      }
      setStatuses(newStatuses);
      execute(() => dispatch(updateTransferConfig(record._id, { ...record, statuses: newStatuses })));
    },
    [execute, dispatch, record],
  );

  const style = loading ? { backgroundColor: '#ebe9e9', pointerEvents: 'none' } : undefined;

  return (
    <Context.Provider value={{ statuses }}>
      <tr style={style} {...props} onChange={onChange} ref={ref} />
    </Context.Provider>
  );
});

export default GridRow;
