import React, { useEffect } from 'react';
import Table from 'antd/lib/table';
import { useVT } from 'virtualizedtableforantd4';

const scrollConfig = {
  y: 500,
  x: true,
};

const SmartTable = ({
  debug = false,
  dataSource = [],
  scroll = scrollConfig,
  onFetch = () => null,
  components = null,
  hasNextPage = true,
  ...props
}) => {
  const [vt, setVt] = useVT(
    () => ({
      onScroll: ({ isEnd }) => {
        if (isEnd && hasNextPage) {
          onFetch();
        }
      },
      scroll: { y: scroll.y },
      debug,
      offset: 50,
    }),
    [dataSource, onFetch, hasNextPage],
  );

  useEffect(() => {
    if (components) {
      setVt(components);
    }
  }, [setVt, components]);

  return <Table dataSource={dataSource} scroll={scroll} components={vt} pagination={false} {...props} />;
};

export default SmartTable;
