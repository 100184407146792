import { Button, Form, Input, InputNumber, Modal, notification, Select } from 'antd';
import { supportedNetworks } from '../../pages/Admin/Totals/config/hrc-config';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hrcRequest, selectTools } from '../../store/tools';
import { useToggle } from '../../hooks/useToggle';
import { useTranslation } from 'react-i18next';

export const HRCTool = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector(selectTools);
  const { activate, deactivate, active } = useToggle();

  const handleFormSubmit = useCallback(async () => {
    try {
      const data = await dispatch(hrcRequest(form.getFieldsValue()));
      if (data.fileURL) {
        window.open(data.fileURL, '_blank');
        notification.success({
          message: t('tools.hrc.messages.success'),
        });
      } else {
        notification.error({
          message: t('tools.hrc.messages.error'),
        });
      }
    } catch (e) {
      notification.error({
        message: t('tools.hrc.messages.error'),
      });
    } finally {
      form.resetFields();
      deactivate();
    }
  }, [form]);

  return (
    <div style={{ float: 'left', padding: '0 20px' }}>
      <Button type="primary" onClick={activate}>
        HRC Tool
      </Button>
      <Modal
        title={t('tools.hrc.modal.title')}
        open={active}
        onCancel={() => {
          form.resetFields();
          deactivate();
        }}
        confirmLoading={loading}
        onOk={handleFormSubmit}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label={t('tools.hrc.form.network')}
            name="network"
            rules={[{ required: true, message: t('tools.hrc.validation.network') }]}
          >
            <Select>
              {supportedNetworks.map((item) => (
                <Select.Option key={item.name} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('tools.hrc.form.tournamentId')}
            name="tournamentId"
            rules={[{ required: true, message: t('tools.hrc.validation.tournamentId') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('tools.hrc.form.chipsAmount')}
            name="chipsAmount"
            rules={[
              { required: true, message: t('tools.hrc.validation.chips.required') },
              {
                type: 'number',
                min: 1,
                message: t('tools.hrc.validation.chips.min'),
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
