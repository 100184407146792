import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'transferConfig',
  initialState: {
    list: {
      page: 1,
      limit: 20,
      totalDocs: 0,
      docs: [],
    },
    loading: false,
    error: null,
  },
  reducers: {
    transferConfigRequestStarted: (transfers) => {
      transfers.loading = true;
    },
    transferConfigRequestFailed: (transfers, action) => {
      transfers.error = action.payload.data;
    },
    transferConfigRequestEnded: (transfers) => {
      transfers.loading = false;
    },
    transferConfigReceived: (transfers, action) => {
      transfers.list = action.payload.data;
    },
    transferConfigAppend: (transfers, action) => {
      transfers.list = {
        ...action.payload.data,
        docs: [...transfers.list.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const {
  transferConfigRequestStarted,
  transferConfigRequestFailed,
  transferConfigRequestEnded,
  transferConfigReceived,
  transferConfigAppend,
} = slice.actions;
export default slice.reducer;

export const getRegions = (params = {}) =>
  apiCallBegan({
    url: '/transfer-config/regions',
    params,
  });

export const addRegion = (region) =>
  apiCallBegan({
    url: '/transfer-config',
    method: 'POST',
    data: { name: region },
    onStart: transferConfigRequestStarted,
    onError: transferConfigRequestFailed,
    onEnd: transferConfigRequestEnded,
  });

export const getTransferConfig = (params = {}, redux = true) => {
  const payload = {
    url: '/transfer-config',
    params,
    onStart: transferConfigRequestStarted,
    onError: transferConfigRequestFailed,
    onEnd: transferConfigRequestEnded,
  };

  if (redux) {
    payload.onSuccess = params?.pagination?.page > 1 ? transferConfigAppend : transferConfigReceived;
  }

  return apiCallBegan(payload);
};

export const updateTransferConfig = (id, data) =>
  apiCallBegan({
    url: `/transfer-config/${id}`,
    method: 'PUT',
    data,
    onStart: transferConfigRequestStarted,
    onError: transferConfigRequestFailed,
    onEnd: transferConfigRequestEnded,
  });

export const removeRegion = (id) =>
  apiCallBegan({
    url: `/transfer-config/${id}`,
    method: 'DELETE',
    onStart: transferConfigRequestStarted,
    onError: transferConfigRequestFailed,
    onEnd: transferConfigRequestEnded,
  });

export const selectTransferConfig = (state) => state.transferConfig.list;

export const selectIsLoading = (state) => state.transferConfig.loading;
