import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Tag } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import SmartTable from 'components/Table/SmartTable';
import CountrySelect from 'components/CountrySelect';
import { getTransferConfig, selectIsLoading, selectTransferConfig } from 'store/transfer-config';
import AccountSelect from '../../components/AccountSelect';
import UpdateConfigModal from './UpdateConfigModal';

const List = () => {
  const dispatch = useDispatch();
  const { addFilter, params, nextPage } = useTableParams({ pagination: { page: 1, pageSize: 20 } });
  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectTransferConfig);

  useEffect(() => {
    dispatch(getTransferConfig(params));
  }, [dispatch, params]);

  const fetch = useCallback(() => {
    dispatch(getTransferConfig());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        key: 'type',
        title: <AccountSelect placeholder="Account" allowClear onChange={addFilter('type')} />,
        dataIndex: 'type',
      },
      {
        key: 'countryFrom',
        title: <CountrySelect placeholder="Country From" allowClear onChange={addFilter('countryFrom')} />,
        dataIndex: 'countryFrom',
      },
      {
        key: 'countryTo',
        title: <CountrySelect placeholder="Country To" allowClear onChange={addFilter('countryTo')} />,
        dataIndex: 'countryTo',
      },
      {
        key: 'statuses',
        title: 'Statuses',
        dataIndex: 'statuses',
        render: (statuses) => (
          <Space>
            {statuses.map((status) => (
              <Tag key={status} color="geekblue">
                {status}
              </Tag>
            ))}
          </Space>
        ),
      },
      {
        key: 'actions',
        dataIndex: '_id',
        title: '',
        align: 'center',
        render: (id, config) => <UpdateConfigModal config={config} onClose={fetch} />,
      },
    ],
    [addFilter, fetch],
  );

  return (
    <SmartTable
      rowKey="_id"
      loading={isLoading}
      columns={columns}
      dataSource={data.docs}
      className="account-regions-table"
      scroll={{ y: 'calc(100vh - 300px)' }}
      onFetch={nextPage}
    />
  );
};

export default List;
