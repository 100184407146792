import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Space, Spin } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { getTransferConfig } from 'store/transfer-config';
import useLoading from 'hooks/useLoading';
import { useTableParams } from 'hooks/useTableParams';
import CountrySelect from 'components/CountrySelect';
import SmartTable from 'components/Table/SmartTable';
import AccountSelect from '../../components/AccountSelect';
import StatusCheckbox from './StatusCheckbox';
import GridRow from './GridRow';

const Grid = () => {
  const [region, setRegion] = useState();
  const [account, setAccount] = useState();
  const dispatch = useDispatch();
  const [data, setData] = useState({ docs: [] });
  const { loading, execute } = useLoading();
  const { params, nextPage } = useTableParams({ pagination: { page: 1, pageSize: 40 } });

  const fetchRegions = useCallback(async () => {
    const res = await execute(() =>
      dispatch(getTransferConfig({ type: account, countryFrom: region, ...params }, undefined)),
    );
    setData((current) => ({ ...res, docs: params.pagination.page > 1 ? [...current.docs, ...res.docs] : res.docs }));
  }, [region, account, execute, dispatch, params]);

  useEffect(() => {
    if (region && account) {
      fetchRegions();
    }
  }, [region, account, fetchRegions]);

  const columns = useMemo(() => {
    const cols = [
      {
        key: 'countryTo',
        dataIndex: 'countryTo',
        title: 'Country To',
      },
    ];

    if (ACCOUNT_REGIONS[account]) {
      ACCOUNT_REGIONS[account].statuses.forEach((status) => {
        cols.push({
          key: status,
          title: status,
          align: 'center',
          render: (_, config) => <StatusCheckbox config={config} value={status} />,
        });
      });
    }

    return cols;
  }, [account]);

  return (
    <>
      <Space>
        <AccountSelect placeholder="Select account" value={account} onChange={setAccount} style={{ width: 200 }} />
        <CountrySelect placeholder="Select country" value={region} onChange={setRegion} style={{ width: 300 }} />
      </Space>
      <Divider />
      {data?.docs.length === 0 && loading ? (
        <div style={{ textAlign: 'center', padding: 24 }}>
          <Spin />
        </div>
      ) : (
        <SmartTable
          loading={loading}
          components={{ body: { row: GridRow } }}
          dataSource={data.docs}
          rowKey="_id"
          onRow={(record) => ({ record })}
          columns={columns}
          onFetch={nextPage}
          className="account-regions-table-grid"
          scroll={{ y: 'calc(100vh - 374px)' }}
          hasNextPage={data.hasNextPage}
        />
      )}
    </>
  );
};

export default Grid;
