import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { Table } from 'antd';

const SortableItem = SortableElement(forwardRef((props, ref) => <tr {...props} ref={ref} />));
const SortableBody = SortableContainer(forwardRef((props, ref) => <tbody {...props} ref={ref} />));

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const SortableTable = ({ onDragSort, dataSource, columns, active = true, ...restProps }) => {
  const [sortedDataSource, setSortedDataSource] = useState([]);

  useEffect(() => {
    if (dataSource) {
      setSortedDataSource(dataSource);
    }
  }, [dataSource]);

  const columnsWithDragHandle = useMemo(
    () =>
      active
        ? [
            {
              dataIndex: 'sort',
              width: 30,
              className: 'drag-visible',
              align: 'center',
              render: () => <DragHandle />,
            },
            ...columns,
          ]
        : columns,
    [columns, active],
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable(sortedDataSource.slice(), oldIndex, newIndex).filter((el) => !!el);
        setSortedDataSource(newData);
        onDragSort({ oldIndex, newIndex });
      }
    },
    [sortedDataSource, onDragSort],
  );

  const DraggableContainer = forwardRef((props, ref) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      ref={ref}
      {...props}
    />
  ));

  const DraggableBodyRow = forwardRef(({ className, style, ...restProps }, ref) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = sortedDataSource.findIndex((x) => x.order === restProps['data-row-key']);

    return <SortableItem index={index} {...restProps} ref={ref} />;
  });

  return (
    <Table
      dataSource={sortedDataSource}
      columns={columnsWithDragHandle}
      rowKey="order"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...restProps}
      pagination={false}
    />
  );
};

export default SortableTable;
