import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'payments',
  initialState: {
    list: { docs: [], hasNextPage: false },
    loading: false,
    error: null,
  },
  reducers: {
    paymentsRequestStarted: (payments) => {
      payments.loading = true;
    },
    paymentsRequestFailed: (payments, action) => {
      payments.error = action.payload.data;
    },
    paymentsRequestEnded: (payments) => {
      payments.loading = false;
    },
    paymentsReceived: (payments, action) => {
      payments.list = action.payload.data;
    },
    paymentsAppend: (payments, action) => {
      payments.list = {
        ...action.payload.data,
        docs: [...payments.list.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const { paymentsRequestStarted, paymentsRequestEnded, paymentsReceived, paymentsAppend } = slice.actions;

export default slice.reducer;

//api calls
export const getAllPayments = (params) =>
  apiCallBegan({
    url: '/payments',
    params: {
      pagination: { page: 1, pageSize: 100 },
      ...params,
    },
    onStart: paymentsRequestStarted,
    onEnd: paymentsRequestEnded,
    onSuccess: params?.pagination?.page > 1 ? paymentsAppend : paymentsReceived,
  });

export const getAllPaymentNames = () =>
  apiCallBegan({
    url: '/payments/names',
  });

export const createPayment = (data) =>
  apiCallBegan({
    url: '/payments',
    method: 'post',
    data,
    onStart: paymentsRequestStarted,
    onEnd: paymentsRequestEnded,
  });

export const recalculate = () =>
  apiCallBegan({
    url: '/payments/recalculate',
    method: 'post',
  });

export const sort = (data) =>
  apiCallBegan({
    url: '/payments/sort',
    method: 'patch',
    data,
  });

export const block = (id) =>
  apiCallBegan({
    url: `/payments/${id}/block`,
    method: 'patch',
  });

export const unblock = (id) =>
  apiCallBegan({
    url: `/payments/${id}/unblock`,
    method: 'patch',
  });

export const selectPayments = createSelector(
  (state) => state.payments,
  (payments) => ({
    list: payments.list,
    loading: payments.loading,
  }),
);
